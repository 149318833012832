import React from 'react';
import { useState, useEffect } from "react";

import Fade from 'react-reveal/Fade';

import $ from 'jquery';

import Form from '../../components/Form/Form';

import { useTranslation } from "react-i18next";

import Header from '../../components/Header';

function Main() {
  const { t } = useTranslation();
  const [ formOpened, setFormOpened ] = useState(false);
  useEffect(() => {
    if (formOpened) {
        $('body').addClass("ovf-hidden");
    } else {
        $('body').removeClass("ovf-hidden");
    }
}, [ formOpened ]);
  return (
    <>
    <Form formOpened={formOpened} setFormOpened={setFormOpened} />
    <Header />
    <div className='bg-mainn h-[770px]'>
      <div className='flex'>
        <div className='blockmain w-full h-[770px]'>
          <div className='max-w-[640px] mx-auto'>
            <Fade left><div className='flex mt-[85px] justify-center xl:justify-start xl:mx-0'><h1 className='text-[#40DDB6] rubik-font text-[22px] md:text-[40px] xl:text-[50px] font-bold'>Global</h1><h1 className='text-[#FFF] rubik-font text-[22px] md:text-[40px] xl:text-[50px] font-bold ml-[10px]'>Support</h1></div></Fade>
            <Fade left delay={300}><h1 className='flex justify-center xl:justify-start xl:mx-0 text-[#FFF] rubik-font text-[20px] md:text-[30px] xl:text-[40px] font-bold'>{t('05who_we_are')}</h1></Fade>
            <Fade left delay={600}><p className='text-center xl:text-left mx-[20px] xl:mx-0 xl:w-[525px] text-[#FFF] text-[17px] rubik-font mt-[30px] font-light'>{t('02six_years_working')}</p></Fade>
            <Fade left delay={900}><p className='text-center xl:text-left  mx-[20px] xl:mx-0 xl:w-[525px] text-[#FFF] text-[17px] rubik-font mt-[15px] font-light'>{t('03expert_in_segment')}</p></Fade>
            <Fade left delay={1200}><p className='text-center xl:text-left  mx-[20px] xl:mx-0 xl:w-[525px] text-[#FFF] text-[17px] rubik-font mt-[30px] font-light'>{t('04clients_trust_us')}</p></Fade>
            <Fade left delay={1500}><a href="https://t.me/IntellectSapport" target="_blank"><div className='button mt-[50px] mx-auto xl:mx-0'>
              <p>{t('main_button')}</p>
            </div></a></Fade>
          </div>
        </div>
        <div className='bg-none w-full h-[770px] hidden xl:block'></div>
      </div>
    </div>
    </>
    
  );
}

export default Main;